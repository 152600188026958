import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
class HttpRequest {
    constructor() {
        this.timeout = 5000;
        if (process.env.NODE_ENV === 'development') {
            this.baseURL = '/api';
        } else {
            this.baseURL = 'https://api.qimingxinli.com';
            //this.baseURL = 'http://43.138.227.188';
        }
    }

    setInterceptors(instance) {
        instance.interceptors.request.use(
            config => {
                // 在发送请求之前做些什么
                config.headers.token = localStorage.getItem('token');
                return config;
            },
            error => {
                // 对请求错误做些什么
                return Promise.reject(error);
            }
        );

        // 添加响应拦截器
        instance.interceptors.response.use(
            response => {
                // 对响应数据做点什么
                if (response.status == 200) {
                    if (response.data.code == 10000) {
                        return Promise.resolve(response.data.data);
                    } else if (response.data.code == 80000) {
                        Vue.prototype.$toast.center('登录过期');
                        setTimeout(() => {
                            const params = window.location.hash.slice(2);
                            const hash = window.location.hash;
                            console.log(params);
                            const r = hash.replace('/home', '/login');

                            r.indexOf('countyName') > 0
                                ? (window.location.hash = r)
                                : (window.location.hash = '/login');
                        }, 2000);
                    } else {
                        return Promise.reject(response.data);
                    }
                }
            },
            error => {
                // 对响应错误做点什么
                return Promise.reject(error);
            }
        );
    }

    mergeOptions(options) {
        return { baseURL: this.baseURL, timeout: this.timeout, ...options };
    }

    get(url, data) {
        return this.request({
            method: 'get',
            url,
            params: data
        });
    }

    post(url, data, formData) {
        // 判断是否 form-data
        if (formData) {
            let form_data = new FormData(); //创建一个form-data格式的对象
            for (let i in data) {
                form_data.append(i, data[i]);
            }

            return this.request({
                method: 'post',
                url,
                data: form_data
            });
        } else {
            return this.request({
                method: 'post',
                url,
                data: data
            });
        }
    }

    request(opts) {
        const instance = axios.create();
        this.setInterceptors(instance);
        const options = this.mergeOptions(opts);
        return instance(options);
    }
}

export default new HttpRequest();
