<template>
    <div class="pileBar" ref="pileBar"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        props: ['payload'],
        watch: {
            city: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        computed: { ...mapState(['city']) },
        name: 'pileBar',
        data: () => ({
            pileBarEcharts: '',
            isTrue: false
        }),
        methods: {
            init(data) {
                const { xljkscqk } = data;
                this.pileBarEcharts = this.$echarts.init(this.$refs.pileBar);
                this.pileBarEcharts.setOption(
                    {
                        ...option,
                        series: [
                            {
                                ...option.series[0],
                                data: [xljkscqk[0].xxsl, xljkscqk[1].czsl, xljkscqk[2].gzsl]
                            },
                            {
                                ...option.series[1],
                                data: [xljkscqk[0].xxjrsl, xljkscqk[1].czjrsl, xljkscqk[2].gzjrsl]
                            },
                            {
                                ...option.series[2],
                                data: [xljkscqk[0].xxbfb, xljkscqk[1].czbfb, xljkscqk[2].gzbfb]
                            }
                        ]
                    },
                    true
                );
                setTimeout(() => {
                    this.pileBarEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    this.pileBarEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .pileBar {
        width: 100%;
        height: 100%;
    }
</style>
