export default {
    //color: ['#D06052', '#E29F39', '#4C9BC7'], //环形颜色
    title: {
        text: `10`,
        top: 'center',
        left: '50%',
        padding: [0, 14, 0, 0],
        textAlign: 'center',
        textStyle: {
            color: '#fff',
            fontSize: 0.2 + 'rem'
        }
    },
    tooltip: {
        show: false, // 是否展示提示框组件,默认true
        backgroundColor: '#2D8CF0', //设置背景颜色
        textStyle: {
            color: 'white' //设置文字颜色
        },
        borderColor: '#2D8CF0' //设置边框颜色
        // 定义Y轴数值
    },
    // 图例组件，旁边的数据颜色表示框
    legend: {
        show: false, // 是否显示，默认true
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        y: '10px',
        x: 'right',
        textStyle: {
            color: '#fff'
        },
        itemGap: 10
    },
    series: [
        {
            //name: '访问来源',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['60%', '80%'],
            hoverAnimation: false,
            itemStyle: {
                //borderColor: '#fff',
                borderWidth: 1
            },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: [
                {
                    value: 100 - 20,
                    itemStyle: {
                        normal: {
                            color: 'rgba(255, 255, 255, 0.2)'
                        }
                    },
                    label: {
                        show: false
                    }
                },
                {
                    value: 20
                }
            ]
        }
    ]
};
