<template>
    <div class="header">
        <div class="content flex-row-space-between">
            <div>
                <span>{{ time }} </span>
                <span style="margin-left:0.2rem;">数据更新时间：{{ updateTime }}</span>
            </div>
            <span>{{ titleName }}青少年心理健康大数据智慧管理中心</span>
            <div>{{ userName }}丨<span @click="outLogin">退出</span></div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        computed: { ...mapState(['titleName']) },
        data: () => ({
            isTrue: false,
            userName: '',
            time: '',
            updateTime: ''
        }),

        mounted() {
            this.updateTime = this.$moment(new Date()).format('YYYY-MM-DD ');
            setInterval(() => (this.time = this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss')), 1000);
            this.userName = localStorage.getItem('userName');
        },
        methods: {
            outLogin() {
                localStorage.removeItem('token');
                this.$router.push('/login');
            }
        }
    };
</script>
<style lang="less">
    @import url('../../common/global.less');

    .header {
        padding: 24/100rem 60/100rem;
        .topOrnate {
            height: 8/100rem;
            background: #000;
        }
        .content {
            height: 59 /100rem;
            padding: 20/100rem 40/100rem 0;
            font-size: 34/100rem;
            font-weight: 400;
            background: url('../../assets/topBg.png') no-repeat;
            background-size: 100%;
            text-align: center;
            position: relative;
            > div {
                font-size: 18/100rem;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: #31d8e5;
                &:last-of-type {
                    cursor: pointer;
                }
            }
            > span {
                color: #333333;
                background: linear-gradient(0deg, #21f9fc 0.5126953125%, #51b7e3 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
</style>
