<template>
    <div class="pie" ref="pie"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        computed: { ...mapState(['city']) },
        props: ['payload', 'index'],
        watch: {
            city: {
                handler(o) {
                    this.$nextTick(() => this.index == 0 && this.init(0));
                },
                immediate: true,
                deep: true
            },
            index: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        name: 'pie',
        data: () => ({}),
        methods: {
            init(i) {
                this.pieEcharts = this.$echarts.init(this.$refs.pie);
                this.pieEcharts.setOption(
                    {
                        ...option,
                        series: [
                            {
                                ...option.series[0],
                                data: this.city.qsnzyxljkwt.data[i]
                            }
                        ]
                    },
                    true
                );
                setTimeout(() => {
                    this.pieEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    this.pieEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .pie {
        width: 100%;
        height: 100%;
    }
</style>
