export default {
    color: ['#5609ee', '#89c171', '#6bafcb', '#4c65b7', '#cc5a5c', '#f3c257', '#836caa', '#9aae69'], //环形颜色
    title: {
        show: false,
        text: '35', //主标题文本
        subtext: '精神异常', //副标题文本
        top: '50%',
        left: '50%',
        padding: [0, 14, 0, 0],
        textAlign: 'center',
        textStyle: {
            color: '#fff',
            fontSize: 14
        },
        subtextStyle: {
            fontFamily: '微软雅黑',
            fontSize: 12,
            color: '#fff'
        }
    },
    tooltip: {
        show: false, // 是否展示提示框组件,默认true
        backgroundColor: 'RGBA(2, 120, 231, 0.8)', //设置背景颜色
        textStyle: {
            color: 'white' //设置文字颜色
        },
        borderColor: 'RGBA(2, 120, 231, 0.8)' //设置边框颜色
    },
    // 图例组件，旁边的数据颜色表示框
    legend: {
        show: false, // 是否显示，默认true
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        y: '-5px',
        x: 'right',
        textStyle: {
            color: '#fff'
        },
        itemGap: 10
    },
    series: [
        {
            name: '',
            //roseType: 'area',
            type: 'pie',
            center: ['50%', '60%'],
            radius: ['40%', '70%'],
            emphasis: {
                //饼图中间显示配置
                label: {
                    show: true,
                    fontSize: '15',
                    fontWeight: 'bold',
                    formatter: '{c} \n {b}'
                }
            },
            itemStyle: {
                //borderColor: '#fff',
                borderWidth: 1
                //borderRadius: '4'
            },
            //label: {
            //    alignTo: 'edge',
            //    formatter: '{name|{b}}\n{time|{c} 人}',
            //    minMargin: 5,
            //    edgeDistance: 50,
            //    lineHeight: 25,
            //    rich: {
            //        name: {
            //            fontSize: 0.15 + 'rem',
            //            color: '#fff'
            //        },
            //        time: {
            //            fontSize: 0.12 + 'rem',
            //            color: '#fff'
            //        }
            //    }
            //},
            // 设置值域的那指向线
            labelLine: {
                normal: {
                    show: false // show设置线是否显示，默认为true，可选值：true ¦ false
                }
            },
            // 设置值域的标签
            label: {
                normal: {
                    show: false,
                    position: 'inner', // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                    // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                    // {a}指series.name  {b}指series.data的name
                    // {c}指series.data的value  {d}%指这一部分占总数的百分比
                    formatter: '{d}%',
                    color: '#fff'
                }
            },

            //labelLine: {
            //    length: 15,
            //    length2: 10,
            //    maxSurfaceAngle: 80,
            //},
            //labelLayout: function(params) {
            //    var isLeft = params.labelRect.x < 200 / 2;
            //    var points = params.labelLinePoints;
            //    // Update the end point.
            //    points[2][0] = isLeft
            //        ? params.labelRect.x
            //        : params.labelRect.x + params.labelRect.width;
            //    return {
            //        labelLinePoints: points,
            //    };
            //},
            data: [
                { name: '小学', value: 50 },
                { name: '初中', value: 140 },
                { name: '高中', value: 180 }
            ]
        }
    ]
};
