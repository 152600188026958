<template>
    <div ref="maps"></div>
</template>
<script type="text/javascript">
    import * as echarts from 'echarts';
    import 'echarts-extension-amap';
    import option from './config/options';
    import MAPDATA from './json/map_data.json';
    import { mapState } from 'vuex';

    export default {
        computed: { ...mapState(['ciytName', 'cityId', 'county', 'historyLength', 'titleName', 'isCounty']) },
        props: ['payload'],
        name: 'maps',
        data: () => ({
            isTrue: true,
            dataIndex: null,
            mapEcharts: '',
            currentName: '', //用来存放当前的地图名字
            history: [], //用来存放下钻的历史记录
            name: '',
            areaAry: ['梅州市', '梅江区', '梅县区', '兴宁市', '平远县', '蕉岭县', '大埔县', '丰顺县', '五华县']
        }),
        //watch: {
        //    payload: {
        //        handler(o) {
        //            console.log(o, 'dfdfds000000');
        //            o && this.$nextTick(() => this.init(o));
        //        },
        //        immediate: true,
        //        deep: true
        //    }
        //},
        async mounted() {
            const district_id = localStorage.getItem('district_id');
            if (district_id != 0) {
                //this.$store.commit('SET_HISTORY_LENGTH', 1);
                this.history[0] = this.areaAry[0];
                this.history[1] = this.areaAry[district_id];
                this.$nextTick(() => {
                    setTimeout(async () => {
                        const newMapJson = await this.getBackMapJson(this.titleName);
                        this.mapEcharts = echarts.init(this.$refs.maps);
                        echarts.registerMap(this.titleName, newMapJson);
                        option.series[0].map = this.titleName;

                        //重新绘制地图
                        this.mapEcharts.setOption(
                            {
                                ...option,
                                series: [
                                    {
                                        ...option.series[0]
                                    }
                                ]
                            },
                            true
                        );
                    }, 500);
                });
                setTimeout(() => {
                    this.init();
                }, 2000);
            } else {
                option.series[0].map = 'meizhou';
                this.history[0] = this.areaAry[0];
                setTimeout(async () => {
                    this.init();
                }, 3000);
            }
        },

        methods: {
            init(payload) {
                //console.log(payload, 'payload,payload,payload');
                this.$store.commit('SET_HISTORY_LENGTH', this.history.length);
                echarts.registerMap('meizhou', MAPDATA); // 注册地图
                this.mapEcharts = echarts.init(this.$refs.maps);
                this.mapEcharts.setOption(
                    {
                        ...option,
                        series: [
                            {
                                ...option.series[0]
                            }
                        ]
                    },
                    true
                );

                this.mapEcharts.off('click'); //防止重复执行
                this.mapEcharts.on('click', async params => {
                    const { dataIndex, name, componentSubType } = params;
                    console.log(dataIndex);
                    this.$store.commit('SET_MAPTYPE', { type: true, name });
                    if (this.historyLength == 1) {
                        this.history[1] = name;
                        const r = await this.$http.get_area_data({ type: dataIndex + 1 });
                        this.$store.commit('SET_CITY', r);
                    }
                    if (this.historyLength == 2) {
                        this.history[2] = name;
                        const r = await this.$http.get_town_data({ type: name });
                        this.$store.commit('SET_CITY', r);
                    }
                    if (componentSubType == 'map') {
                        this.goDown(name);
                    }
                    this.$store.commit('SET_HISTORY_LENGTH', this.history.length);
                    console.log(this.history, 'this.history');
                });

                setTimeout(() => {
                    this.mapEcharts.resize();
                }, 300);

                window.addEventListener('resize', () => {
                    this.mapEcharts.resize();
                });
            },

            async goDown(name) {
                console.log(name, 'goDown名字11111111111111111111');

                //获取地图数据之后，修改地图options
                const mapname = name;

                if (!echarts.getMap(name)) {
                    console.log(name, '999999');
                    const newMapJson = await this.getMapJson(name);
                    echarts.registerMap(mapname, newMapJson);
                }

                option.series[0].map = mapname;

                //重新绘制地图
                this.mapEcharts.setOption(
                    {
                        ...option,
                        series: [
                            {
                                ...option.series[0]
                            }
                        ]
                    },
                    true
                );
                this.currentName = name;
            },

            async getMapJson(name) {
                console.log(name, 'dfdfdfdfdf');
                console.log(this.historyLength);
                //console.log(this.history.length);

                let url =
                    this.historyLength == 1
                        ? `./json/${name}.json`
                        : `./json/${this.history[1]}/${this.history[2] || name}.json`;
                console.log(url);
                const jsonData = await import(`${url}`);

                //if (this.history.length == 1 || (this.historyLength > 0 && this.historyLength < 2)) {
                //    this.$store.commit('SET_HISTORY_LENGTH', 2);
                //}
                //if (this.history.length == 2 && this.historyLength == 2) {
                //    //this.$store.commit('SET_HISTORY_LENGTH', 1);
                //}
                return jsonData.default;
            },

            async getBackMapJson(name) {
                console.log(this.history.length, '长度');

                if (this.history.length == 2) {
                    this.$store.commit('SET_HISTORY_LENGTH', this.history.length);
                }
                let url = this.history.length == 1 ? `./json/map_data.json` : `./json/${name}.json`;
                console.log(url);
                const jsonData = await import(`${url}`);
                return jsonData.default;
            },

            // 返回
            async backHistory() {
                console.log(this.historyLength, '返回长度');
                let mapname = '';

                if (this.historyLength == 2) {
                    this.history.pop();
                    this.$store.commit('SET_HISTORY_LENGTH', this.history.length);
                    const r = await this.$http.get_city_data();
                    this.$store.commit('SET_CITY', r);
                    this.$store.commit('SET_MAPTYPE', { type: true, name: '全市' });
                    mapname = this.history[0];
                    const newMapJson = await this.getBackMapJson(mapname);
                    echarts.registerMap(mapname, newMapJson);
                    option.series[0].map = mapname;

                    //重新绘制地图
                    this.mapEcharts.setOption(
                        {
                            ...option,
                            series: [
                                {
                                    ...option.series[0]
                                }
                            ]
                        },
                        true
                    );
                    this.currentName = this.name;
                    return;
                }
                if (this.historyLength == 3) {
                    this.history.pop();
                    this.$store.commit('SET_HISTORY_LENGTH', this.history.length);
                    this.$store.commit('SET_MAPTYPE', { type: true, name: this.history[this.history.length - 1] });
                    mapname = this.history[1];
                    const area = [
                        '梅州市',
                        '梅江区',
                        '梅县区',
                        '兴宁市',
                        '平远县',
                        '蕉岭县',
                        '大埔县',
                        '丰顺县',
                        '五华县'
                    ];
                    area.forEach(async (item, index) => {
                        if (item == mapname) {
                            setTimeout(async () => {
                                const r = await this.$http.get_area_data({ type: index });
                                this.$store.commit('SET_CITY', r);
                            }, 500);
                        }
                    });
                    const newMapJson = await this.getBackMapJson(mapname);
                    echarts.registerMap(mapname, newMapJson);
                    option.series[0].map = mapname;

                    //重新绘制地图
                    this.mapEcharts.setOption(
                        {
                            ...option,
                            series: [
                                {
                                    ...option.series[0]
                                }
                            ]
                        },
                        true
                    );
                    this.currentName = this.name;
                    return;
                }
                const newMapJson = await this.getBackMapJson(mapname);
                echarts.registerMap(mapname, newMapJson);
                option.series[0].map = mapname;

                //重新绘制地图
                this.mapEcharts.setOption(
                    {
                        ...option,
                        series: [
                            {
                                ...option.series[0]
                            }
                        ]
                    },
                    true
                );
                this.currentName = this.name;
            }
        },
        destroyed() {
            console.log('销毁');
            //const myChart = echarts.init(this.$refs.maps);
            this.mapEcharts.dispose();
        }
    };
</script>
<style lang="less" scoped>
    .maps {
        width: 100%;
        height: 100%;
    }
</style>
