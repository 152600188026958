<template>
    <div class="category" ref="category"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        computed: { ...mapState(['city']) },
        props: ['index'],
        watch: {
            city: {
                handler() {
                    this.$nextTick(() => {
                        this.index == 0 && this.init(0);
                    });
                },
                immediate: true,
                deep: true
            },
            index: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        name: 'category',
        data: () => ({}),
        methods: {
            init(i) {
                console.log(i, 99);
                const pieEcharts = this.$echarts.init(this.$refs.category);
                pieEcharts.setOption(
                    {
                        ...option,
                        xAxis: { ...option.xAxis, data: this.city && this.city.yjrxtxxqk[i].title },
                        series: [
                            { ...option.series[0], data: this.city && this.city.yjrxtxxqk[i].bar },
                            { ...option.series[1], data: this.city && this.city.yjrxtxxqk[i].line }
                        ]
                    },
                    true
                );
                setTimeout(() => {
                    pieEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    pieEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .category {
        width: 100%;
        height: 100%;
    }
</style>
