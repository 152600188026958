export default {
    legend: {
        show: true,
        textStyle: {
            color: 'white' //设置文字颜色
        }
    },
    tooltip: {
        show: true,
        backgroundColor: 'RGBA(2, 120, 231, 0.8)', //设置背景颜色
        textStyle: {
            color: 'white' //设置文字颜色
        },
        borderColor: 'RGBA(2, 120, 231, 0.8)' //设置边框颜色
    },

    grid: {
        left: '0',
        top: '35',
        right: '15',
        bottom: '0',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        splitLine: {
            show: false,
            //网格线
            lineStyle: {
                type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#fff' //更改坐标轴文字颜色
                //fontSize: 0.18 + 'rem', //更改坐标轴文字大小
            }
        }
    },
    yAxis: {
        type: 'category',
        data: ['小学', '初中', '高中']
    },
    series: [
        {
            name: '总数',
            type: 'bar',
            stack: 'total',
            emphasis: {
                focus: 'series'
            },
            barWidth: '60%',
            label: {
                color: '#fff',
                show: true
                //formatter: '{c}%' // 给计算后的数值添加%
            },
            data: [10, 20, 30]
        },
        {
            name: '接入数量',
            type: 'bar',
            stack: 'total',
            emphasis: {
                focus: 'series'
            },
            barWidth: '60%',
            label: {
                show: true,
                color: '#fff'
                //formatter: '{c}%' // 给计算后的数值添加%
            },
            data: [40, 50, 60]
        },
        {
            name: '百分比',
            type: 'bar',
            stack: 'total',
            emphasis: {
                focus: 'series'
            },
            barWidth: '60%',
            label: {
                show: true,
                color: '#fff',
                formatter: '{c}%' // 给计算后的数值添加%
            },
            data: [70, 80, 90]
        }
    ]
};
