export default {
    tooltip: {
        show: true,
        trigger: 'item'
    },

    series: [
        {
            type: 'map',
            map: 'meizhou',
            mapType: 'meizhou',
            aspectScale: 1, //长宽⽐
            zoom: 1.15, //缩放比例能控制echart图形在dom中的大小
            roam: true,
            label: {
                show: true,
                fontSize: '14px',
                color: '#fff'
            },
            itemStyle: {
                areaColor: 'RGBA(17, 27, 64, 1)',
                borderColor: 'RGBA(56, 155, 183, 0.8)',

                silent: true,
                color: '#fff',
                fontWeightL: 700,
                shadowBlur: 20,
                shadowColor: 'RGBA(56, 155, 183, 0.5)',
                borderWidth: 2,
                fontWeightL: 100,
                shadowBlur: 10
            },
            emphasis: {
                label: {
                    formatter: '{b|{b}}',
                    //position: 'right',
                    color: 'rgba(11, 22, 83, 1)',
                    show: true,
                    rich: {
                        b: {
                            color: '#fff',
                            //lineHeight: 33,
                            //backgroundColor: 'rgba(0,0,0,0.4)',
                            //padding: [8, 14],
                            //borderRadius: 50,
                            fontSize: 0.24 + 'rem'
                        }
                    }
                },
                itemStyle: {
                    areaColor: 'rgba(0, 194, 228, 0.6)'
                }
            },
            select: {
                label: {
                    color: '#fff',
                    fontSize: 0.18 + 'rem'
                },
                itemStyle: {
                    areaColor: 'rgba(0, 194, 228, 0.6)'
                }
            },
            data: [
                {
                    name: '梅江区',
                    value: 1
                },
                {
                    name: '梅县区',
                    value: 1
                },
                {
                    name: '兴宁市',
                    value: 1
                }
            ],
            tooltip: {
                show: false,
                trigger: 'item',
                formatter({ data }) {
                    if (!data) return;
                    let str = `${data.name}<br/>
                                总数：${data.value}<br/>`;
                    return str;
                }
            }
        }
    ]
};
