import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/common/global.less';
import 'element-ui/lib/theme-chalk/index.css';
import './common/iconfont/iconfont.css';
import './common/rem';
import api from './api/index';
import * as echarts from 'echarts';
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
import Moment from 'moment';

Vue.prototype.$echarts = echarts;
Vue.prototype.$http = api;
Vue.prototype.$moment = Moment;
Vue.config.productionTip = false;
import Element from 'element-ui';
Vue.use(Element, { size: 'small', zIndex: 3000 }).use(Toast);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
