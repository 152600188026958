<template>
    <div class="tabbar">
        <div class="flex-row-space-between">
            <div
                v-for="(item, index) in list"
                :key="index"
                :class="index == idx ? 'current' : ''"
                @click="currentItem(index)"
            >
                {{ item }}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: () => ({
            list: ['全部', '小学', '初中', '高中'],
            idx: 0
        }),
        props: {
            aa: {
                type: Function,
                default: null
            }
        },
        mounted() {},
        methods: {
            currentItem(index) {
                this.idx = index;
                this.aa(index);
            }
        }
    };
</script>
<style lang="less">
    .tabbar {
        font-size: 10/100rem;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        width: 160/100rem;
        border: 1px solid #1f6080;
        border-radius: 50/100rem;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        right: 10/100rem;
        //top: 15/100rem;
        margin-bottom: 20/100rem;
        margin-top: 6/100rem;
        z-index: 9999;
        > div > div {
            flex: 1;
            text-align: center;
            border-right: 1px solid #1f6080;
            padding: 2/100rem 0;
            &:last-of-type {
                border-right: none;
            }
        }
        > div > div.current {
            background: #1f6080;
            color: #fff;
        }
    }
</style>
