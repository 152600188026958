import config from './config';
import axios from '../utils/request';

const options = {
    login: options => axios.post(config.login, options),
    get_city_data: options => axios.post(config.get_city_data, options),
    get_area_data: options => axios.post(config.get_area_data, options),
    get_town_data: options => axios.post(config.get_town_data, options),
    get_school_data: options => axios.post(config.get_school_data, options),
    get_content_data: options => axios.post(config.get_content_data, options),
    get_data: options => axios.post(config.get_data, options)
};

export default options;
