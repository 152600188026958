<template>
    <div class="category" ref="category"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        computed: { ...mapState(['city']) },
        props: ['payload'],
        watch: {
            city: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        name: 'pie',
        data: () => ({
            pieEcharts: '',
            isTrue: false
        }),
        methods: {
            init() {
                this.pieEcharts = this.$echarts.init(this.$refs.category);
                let result = 0;
                let ary = [...this.city.xljkjyjsczrs];
                let nameAry = [];
                this.city.xljkjyjsczrs.forEach((item, index) => {
                    result += Number(item.value);
                    nameAry.push(item.name);
                });
                ary.unshift({ name: '总数', value: result });
                this.pieEcharts.setOption(
                    {
                        ...option,
                        series: [
                            {
                                ...option.series[0],
                                data: ary
                            }
                        ]
                    },
                    true
                );
                setTimeout(() => {
                    this.pieEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    this.pieEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .category {
        width: 100%;
        height: 100%;
    }
</style>
