import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mapType: true,
        titleName: '全市',
        city: '',
        type: 0,
        historyLength: 0,
        isTown: false,
        ciytName: '',
        cityId: '',
        county: '',
        isCounty: false
    },
    mutations: {
        SET_MAPTYPE(state, { name }) {
            state.titleName = name;
        },
        SET_CITY(state, data) {
            Vue.set(state, 'city', data);
        },
        SET_ISCOUNTY(state, data) {
            Vue.set(state, 'isCounty', data);
        },
        SET_TYPE(state, data) {
            Vue.set(state, 'type', data);
        },
        SET_HISTORY_LENGTH(state, data) {
            console.log(data);
            Vue.set(state, 'historyLength', data);
        },
        SET_AREA(state, data) {
            Vue.set(state, 'areaId', data);
        },
        SET_ISTOWN(state, data) {
            Vue.set(state, 'isTown', data);
        },
        SET_CITYNAME(state, data) {
            Vue.set(state, 'ciytName', data);
        },
        SET_CITYID(state, data) {
            Vue.set(state, 'cityId', data);
        },
        SET_COUNTY(state, data) {
            Vue.set(state, 'county', data);
        }
    },
    actions: {},
    modules: {}
    //plugins: [createPersistedState()]
});
