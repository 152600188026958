export default {
    color: ['#4C9BC7'], //环形颜色
    tooltip: {
        trigger: 'axis',

        axisPointer: {
            type: 'shadow'
        }
    },
    legend: {},
    grid: {
        left: '0',
        top: '15',
        right: '35',
        bottom: '0',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        splitLine: {
            show: false,
            //网格线
            lineStyle: {
                type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
            }
        }
        //boundaryGap: [0, 0.01]
    },
    yAxis: {
        type: 'category',
        inverse: true,
        data: ['总数', 'A证', 'B证', 'C证']
    },
    series: [
        {
            type: 'bar',
            data: [
                { name: '总数', value: 15 },
                { name: '心理健康教育教师持证人数【A证】', title: '心理健康教育教师持证人数【A证】', value: '10' }
            ]
        }
    ]
};
