<template>
    <div class="pie" ref="pie"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        computed: { ...mapState(['city']) },
        props: ['payload', 'color', 'index', 'type'],
        watch: {
            city: {
                handler() {
                    this.$nextTick(() => {
                        this.index == 0 && this.init(0);
                    });
                },
                immediate: true,
                deep: true
            },
            index: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        name: 'pie',
        data: () => ({
            pieEcharts: '',
            isTrue: false
        }),
        methods: {
            init(i) {
                const obj = {
                    ...option,
                    color: [this.color ? `${this.color}` : ''],
                    series: [
                        {
                            ...option.series[0],
                            data: [
                                {
                                    value: 100 - parseInt(this.city.xlwjyjjgyqk[i][this.type]),
                                    itemStyle: {
                                        normal: {
                                            color: 'rgba(255, 255, 255, 0.2)'
                                        }
                                    },
                                    label: {
                                        show: false
                                    }
                                },
                                {
                                    value: parseInt(this.city.xlwjyjjgyqk[i][this.type])
                                }
                            ]
                        }
                    ]
                };
                obj.title.text = this.city.xlwjyjjgyqk[i][this.type];
                this.pieEcharts = this.$echarts.init(this.$refs.pie);
                this.pieEcharts.setOption(obj, true);
                setTimeout(() => {
                    this.pieEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    this.pieEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .pie {
        width: 100%;
        height: 100%;
    }
</style>
