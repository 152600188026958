<template>
    <div class="home">
        <headers />
        <div class="main flex-row-space-between">
            <ul class="flex-column-space-between">
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <div class="tt">{{ titleName }}心理健康筛查情况</div>
                        <main>
                            <!--<div>
                                <section v-for="(item, index) in city.xljkscqk" :key="index">
                                    <p>{{ item.name }}：{{ item.value }}所</p>
                                    <div class="speed">
                                        <p :style="{ width: `${item.total}%` }"></p>
                                    </div>
                                </section>
                            </div>-->
                            <div class="chart-cont">
                                <pileBar></pileBar>
                            </div>
                        </main>
                    </div>
                </li>
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <div class="tt">
                            {{ titleName }}已接入系统学校情况
                            <tabbar :aa="aa" v-if="historyLength != 3"></tabbar>
                        </div>
                        <main>
                            <div class="chart-cont" style="padding-top:0.25rem;" v-if="historyLength != 3">
                                <homeBar :index="homeBarInd" />
                            </div>
                            <section v-else>
                                <div v-for="(item, index) in city.yjrxtxxqk" :key="index">
                                    <a
                                        v-for="(items, indexs) in item"
                                        target="black"
                                        style="display: block;margin-bottom: 4px;"
                                        :href="!isCounty ? items.url : void 0"
                                        :key="indexs"
                                        >{{ items.school }}
                                    </a>
                                </div>
                            </section>
                        </main>
                    </div>
                </li>
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <div class="tt">{{ titleName }}心理健康辅导中心/站点</div>
                        <main>
                            <ol class="marquee">
                                <li
                                    v-for="(item, index) in city.xljkfdzx"
                                    :key="index"
                                    @click="detail(index)"
                                    style="cursor: pointer;"
                                >
                                    {{ item }}
                                </li>
                                <li v-if="!`${city.xljkfdzx}`" style="text-align:center;">暂无数据</li>
                            </ol>
                        </main>
                    </div>
                </li>
            </ul>
            <ul class="flex-column-space-between">
                <li class="flex-row-space-between">
                    <div v-for="(item, index) in city.school" :key="index">
                        <p>{{ titleName }}{{ item.title }}</p>
                        <p>{{ item.value }}</p>
                    </div>
                </li>
                <li>
                    <div class="maps" style="width:100%;height:99%;">
                        <div class="btn flex-row-space-between" v-if="historyLength">
                            <div class="item" @click.stop="backHistory" v-show="district_id == 0 && historyLength == 2">
                                返回
                            </div>
                            <div class="item" @click.stop="backHistory" v-show="district_id > 0 && historyLength == 3">
                                返回
                            </div>
                            <div class="item" @click.stop="backHistory" v-show="district_id == 0 && historyLength == 3">
                                返回
                            </div>
                            <router-link to="detail">
                                <!--v-if="historyLength == 1"-->
                                <div
                                    class="item"
                                    style="zIndex:999999999;"
                                    v-if="historyLength == 2 || historyLength == 3"
                                >
                                    已测评学校
                                </div>
                            </router-link>
                        </div>
                        <maps style="width:100%;height:100%;" ref="childMap" :payload="citys" />
                    </div>
                    <div class="map-bg"></div>
                </li>
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <ol>
                            <li v-for="(item, index) in city.wjggl" :key="index">
                                <a :href="item.value" target="blank">{{ item.name }}</a>
                            </li>
                        </ol>
                    </div>
                </li>
            </ul>
            <ul class="flex-column-space-between">
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <div class="tt">{{ titleName }}心理健康教师持证情况</div>
                        <main class="flex-row-space-between">
                            <!--<div class="content">
                                <div class="flex-column-left-between">
                                    <p v-for="(item, index) in city.xljkjsczqk" :key="index">
                                        {{ item.name }}：{{ item.value }} {{ index == 1 ? '（次）' : '（人）' }}
                                    </p>
                                </div>
                            </div>-->
                            <!--<div class="chart-cont">
                                <pieBar></pieBar>
                            </div>-->
                            <div class="chart-cont">
                                <pieBar></pieBar>
                            </div>
                        </main>
                    </div>
                </li>
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <div class="tt">{{ titleName }}青少年主要心理健康问题 <tabbar :aa="bb"></tabbar></div>
                        <main class="flex-row-space-between">
                            <div class="tag" v-if="city">
                                <!--<yun style="width:100%;height:100%; "></yun>-->
                                <span v-for="(item, index) in city.qsnzyxljkwt.data[annularPiesIdx]" :key="index">
                                    {{ item.name }}
                                </span>

                                <!--<span>社会焦虑</span>
                                <span>过敏倾向</span>
                                <span>学习焦虑</span>
                                <span>自责焦虑</span>
                                <span>孤独倾向</span>
                                <span>身体症状</span>
                                <span>冲动倾向</span>-->
                            </div>
                            <div class="chart-cont">
                                <annularPies :index="annularPiesIdx" />
                            </div>
                        </main>
                    </div>
                </li>
                <li>
                    <img src="../assets/1.png" width="30%" height="30%" class="one border-top" />
                    <img src="../assets/2.png" width="30%" height="30%" class="one border-right" />
                    <img src="../assets/3.png" width="50%" height="50%" class="one border-bottom" />
                    <img src="../assets/4.png" width="30%" height="30%" class="one border-left" />
                    <div class="main-content">
                        <div class="tt">{{ titleName }}心理危机预警情况 <tabbar :aa="cc"></tabbar></div>
                        <main class="flex-row-space-between">
                            <div class="chart-cont">
                                <bightPie color="#CC0000" :index="bightPieIdx" type="a"></bightPie>
                                <p>总预警人数</p>
                            </div>
                            <div class="chart-cont">
                                <bightPie color="#0087CA" :index="bightPieIdx" type="b"></bightPie>
                                <p>一级预警人数</p>
                            </div>
                            <div class="chart-cont">
                                <bightPie color="#FBCB00" :index="bightPieIdx" type="c"></bightPie>
                                <p>二级预警人数</p>
                            </div>
                            <div class="chart-cont">
                                <bightPie color="#61B42E" :index="bightPieIdx" type="d"></bightPie>
                                <p>三级预警人数</p>
                            </div>
                        </main>
                    </div>
                </li>
            </ul>
        </div>
        <footer>
            <div class="bottom-bg">
                <img src="../assets/bottomBg.png" />
            </div>
            <div class="flex-row-center">
                <span>共青团中央青少年心理咨询与法律援助热线：{{ footer.help_tel }}</span>
                <span>微信公众号：{{ footer.vx }}</span>
                <span>网站：{{ footer.wap }}</span>
                <span>{{ titleName }}校外青少年心理健康辅导中心热线：{{ footer.mid_tel }}</span>
            </div>
            <div class="flex-row-center">
                <span>线下站点：{{ footer.line }}</span>
                <a href="https://beian.miit.gov.cn/" target="blank" style="color:rgba(44, 160, 249, 0.8);">
                    粤ICP备2022061981号-1
                </a>
            </div>
        </footer>
        <!-- 弹框 -->
        <div class="layout" v-show="layoutShow" @click="layoutShow = false">
            <div class="content">
                <div>
                    <div v-html="siteContent"></div>
                </div>
                <button class="btn" @click="layoutShow = false">关闭</button>
            </div>
        </div>
    </div>
</template>
<script>
    import headers from '../components/head';
    import maps from '../components/map';
    import pileBar from '../components/pileBar';
    import homeBar from '../components/homeBar';
    import pieBar from '../components/pieBar';
    import yun from '../components/yun';
    import annularPies from '../components/annularPie';
    import bightPie from '../components/bightPie';
    import tabbar from '../components/tabbar';
    import { mapState } from 'vuex';
    export default {
        computed: { ...mapState(['mapType', 'titleName', 'city', 'historyLength', 'isTown', 'type', 'isCounty']) },
        components: { headers, maps, pileBar, homeBar, pieBar, yun, annularPies, bightPie, tabbar },
        data: () => ({
            show: true,
            order: {
                mapData: [],
                cate: '',
                list: ''
            },
            time: '',
            homeBarInd: 0,
            bightPieIdx: 0,
            annularPiesIdx: 0,
            countyName: '',
            layoutShow: false,
            siteContent: '',
            areaAry: ['梅州市', '梅江区', '梅县区', '兴宁市', '平远县', '蕉岭县', '大埔县', '丰顺县', '五华县'],
            district_id: null,
            footer: '',
            citys: ''
        }),
        async mounted() {
            this.$nextTick(async () => {
                const district_id = localStorage.getItem('district_id');
                const userid = localStorage.getItem('userid');
                this.district_id = district_id;
                this.footer = await this.$http.get_data({ type: district_id });
                console.log(this.footer);
                if (district_id == 0) {
                    this.$store.commit('SET_HISTORY_LENGTH', 0);
                    const timer = setTimeout(async () => {
                        const r = await this.$http.get_city_data();
                        this.citys = r;
                        this.$store.commit('SET_ISTOWN', true);
                        this.$store.commit('SET_CITY', r);
                        this.$refs.childMap.init();
                        this.$store.commit('SET_MAPTYPE', { type: true, name: '全市' });
                        clearTimeout(timer);
                    }, 1000);
                } else {
                    this.$store.commit('SET_MAPTYPE', { name: this.areaAry[district_id] });
                    this.$store.commit('SET_ISCOUNTY', false);
                    this.$store.commit('SET_ISTOWN', true);
                    this.$store.commit('SET_HISTORY_LENGTH', 1);
                    const timer = setTimeout(async () => {
                        this.$store.commit('SET_HISTORY_LENGTH', 1);
                        const r = await this.$http.get_area_data({ type: district_id, district_id, userid });
                        this.citys = r;
                        this.$store.commit('SET_CITY', r);
                        console.log(this.$store.state);
                        this.$refs.childMap.init(r);
                        clearTimeout(timer);
                    }, 1000);
                }
            });
        },
        methods: {
            aa(index) {
                this.homeBarInd = index;
            },
            bb(index) {
                this.annularPiesIdx = index;
            },
            cc(index) {
                this.bightPieIdx = index;
            },
            backHistory() {
                this.$refs.childMap.backHistory(this.type);
            },
            async detail(id) {
                const r = await this.$http.get_content_data({ id });
                const rag = r.replace(/<img/gi, '<img style="width:100%;"');
                this.siteContent = rag;
                this.layoutShow = true;
                console.log(r);
            }
        }
    };
</script>
<style lang="less">
    @import url('../common/global.less');

    @keyframes fadenum {
        0% {
            width: 0;
        }
        100% {
            opacity: 100%;
        }
    }

    .home {
        width: 100vw;
        height: 100vh;
        background: url('../assets/homeBg.png') no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
        .header {
            padding: 24/100rem 60/100rem;
            .topOrnate {
                height: 8/100rem;
                background: #000;
            }
            .content {
                height: 59 /100rem;
                padding: 20/100rem 40/100rem 0;
                font-size: 34/100rem;
                font-weight: 400;
                background: url('../assets/topBg.png') no-repeat;
                background-size: 100%;
                text-align: center;
                position: relative;
                > div {
                    font-size: 18/100rem;
                    font-family: MicrosoftYaHei;
                    font-weight: 400;
                    color: #31d8e5;
                }
                > span {
                    color: #333333;
                    background: linear-gradient(0deg, #21f9fc 0.5126953125%, #51b7e3 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .main {
            padding: 20/100rem;
            height: calc(100vh - 2.28rem);

            .border-top {
                position: absolute;
                top: 0;
                left: 0;
            }
            .border-right {
                position: absolute;
                top: 0;
                right: 0;
            }
            .border-bottom {
                position: absolute;
                bottom: 0;
                right: 0;
            }
            .border-left {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .main-content {
                height: 80%;

                .tt {
                    height: 20%;
                    font-size: 24/100rem;
                    font-family: MicrosoftYaHei;
                    font-weight: bold;
                    color: #80eff5;
                }
                .chart-cont {
                    width: 100%;
                    height: 100%;
                }
                main {
                    height: 100%;

                    > div {
                        flex: 1;
                        > section {
                            margin-bottom: 20/100rem;
                            > p {
                                font-size: 12/100rem;
                                color: #fff;
                                box-sizing: border-box;
                            }
                            div.speed {
                                width: 100%;
                                height: 10/100rem;
                                border-radius: 50/100rem;
                                overflow: hidden;
                                margin-top: 5/100rem;

                                p {
                                    height: 10/100rem;
                                    border-radius: 20/100rem;
                                    //transition: all 1s ease-in;
                                    animation: fadenum 1s;
                                }
                            }

                            &:nth-of-type(1) {
                                .speed {
                                    p {
                                        background: #5470c6;
                                    }
                                }
                            }
                            &:nth-of-type(2) {
                                .speed {
                                    p {
                                        background: #91cc75;
                                    }
                                }
                            }
                            &:nth-of-type(3) {
                                .speed {
                                    p {
                                        background: #fac858;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            > ul:nth-of-type(2) {
                width: 1200/100rem;
                height: 100%;
                > li {
                    &:first-of-type {
                        width: 100%;
                        padding: 0 20/100rem;
                        height: 70/100rem;
                        box-sizing: border-box;
                        > div {
                            width: 22%;
                            height: 100%;
                            font-size: 18/100rem;
                            text-align: center;
                            background: RGBA(9, 19, 46, 0.6);
                            border: 1px solid #1366b4;
                            box-shadow: inset 0px 3px 7px 0px #356b9e;
                            border-radius: 10/100rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            p {
                                &:first-of-type {
                                    color: #fff;
                                }
                                &:last-of-type {
                                    color: #77fcf5;
                                }
                            }
                        }
                    }
                    &:nth-of-type(2) {
                        width: 100%;
                        height: 82%;
                        position: relative;
                        .maps {
                            position: relative;
                            z-index: 999;
                            .btn {
                                width: 100%;
                                position: absolute;
                                top: 20/100rem;
                                right: 20/100rem;
                                padding-left: 20/100rem;
                                box-sizing: border-box;
                                z-index: 999999;
                                .item {
                                    font-size: 14/100rem;
                                    color: #fff;
                                    padding: 8/100rem 20/100rem;
                                    background: rgba(0, 194, 228, 0.6);
                                    border-radius: 80px;
                                    cursor: pointer;
                                    &:last-of-type {
                                        margin-left: 20/100rem;
                                    }
                                }
                            }
                        }

                        .map-bg {
                            width: 100%;
                            height: 350/100rem;
                            background: url('../assets/map-bg.png') no-repeat;
                            background-size: 100%;
                            position: absolute;
                            bottom: -20%;
                            left: 0;
                            z-index: 1;
                        }
                    }
                    // 红头文件
                    &:last-of-type {
                        width: 100%;
                        height: 18%;
                        position: relative;
                        z-index: 999999;
                        .main-content {
                            height: 100%;
                            padding: 20/100rem;
                            box-sizing: border-box;

                            overflow: hidden;
                            ol {
                                height: 100%;
                                overflow-y: scroll;
                                &::-webkit-scrollbar {
                                    width: 10/100rem;
                                    display: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10/100rem;
                                    -webkit-box-shadow-: inset 0 0 5px #2a7af5;
                                    background: #4d93ff;
                                }

                                &::-webkit-scrollbar-track {
                                    border-radius: 10px;
                                    background: rgba(77, 147, 255, 0.2);
                                }
                                > li {
                                    font-size: 16/100rem;
                                    color: #fff;
                                    margin-top: 20/100rem;

                                    a:hover {
                                        color: #2a7af5;
                                    }
                                    &:first-of-type {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ul:first-of-type,
            ul:last-of-type {
                width: 700/100rem;
                height: 100%;
                box-sizing: border-box;
                > li {
                    padding: 10/100rem 20/100rem;
                    box-sizing: border-box;

                    position: relative;
                    &:first-of-type {
                        width: 100%;
                        height: 30%;
                    }
                    &:nth-of-type(2) {
                        width: 100%;
                        height: 35%;
                    }
                    &:last-of-type {
                        width: 100%;
                        height: 30%;
                    }
                }
            }

            ul:first-of-type {
                padding-right: 20/100rem;
                > li {
                    // 全市心理健康筛查情况
                    &:first-of-type {
                        //padding-right: 0;
                    }
                    // 已接入系统学校情况
                    &:nth-of-type(2) {
                        section {
                            height: 100%;
                            overflow-y: auto;
                            font-size: 12/100rem;
                            color: #fff;
                            padding: 0;
                            &::-webkit-scrollbar {
                                width: 10/100rem;
                                display: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10/100rem;
                                -webkit-box-shadow-: inset 0 0 5px #2a7af5;
                                background: #4d93ff;
                            }

                            &::-webkit-scrollbar-track {
                                border-radius: 10px;
                                background: rgba(77, 147, 255, 0.2);
                            }
                            p {
                                margin-bottom: 20/100rem;
                            }
                        }
                    }
                    // 全市心理健康辅导中心/站点
                    &:last-of-type {
                        main {
                            overflow: hidden;
                            overflow-y: scroll;
                            &::-webkit-scrollbar {
                                width: 10/100rem;
                                display: none;
                            }
                            > ol {
                                width: 100%;
                                height: 100%;
                                overflow-y: scroll;
                                box-sizing: border-box;
                                &::-webkit-scrollbar {
                                    width: 10/100rem;
                                    display: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10/100rem;
                                    -webkit-box-shadow-: inset 0 0 5px #2a7af5;
                                    background: #4d93ff;
                                }

                                &::-webkit-scrollbar-track {
                                    border-radius: 10px;
                                    background: rgba(77, 147, 255, 0.2);
                                }

                                li {
                                    height: 40/100rem;
                                    margin: 10/100rem 0;
                                    font-size: 16/100rem;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            ul:last-of-type {
                padding-left: 20/100rem;

                li {
                    //全市心理健康教师持证情况
                    &:first-of-type {
                        padding-right: 0;
                        main {
                            width: 100%;
                            > div.content {
                                height: 60%;
                                display: flex;
                                align-content: center;
                                > div {
                                    width: 90%;
                                    padding: 10/100rem;
                                    box-sizing: border-box;
                                    background: RGBA(9, 19, 46, 0.6);
                                    border: 1px solid #1366b4;
                                    box-shadow: inset 0px 0px 10px 0px #356b9e;
                                    border-radius: 10/100rem;
                                }

                                p {
                                    font-size: 12/100rem;
                                    font-weight: bold;
                                    color: #f2f3f5;
                                }
                            }
                        }
                    }
                    //全市青少年主要心理健康问题
                    &:nth-of-type(2) {
                        padding-right: 0;
                        main {
                            > div:first-of-type {
                                overflow: hidden;
                                height: 100%;
                                position: right;
                            }
                        }
                    }
                    //全市心理危机预警及干预情况
                    &:last-of-type {
                        main {
                            > div {
                                height: 50%;
                                flex: 1;
                                p {
                                    font-size: 12/100rem;
                                    color: #fff;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
            .tag {
                position: relative;
                font-weight: bold;
                span {
                    position: absolute;
                    &:nth-of-type(1) {
                        font-size: 14px;
                        color: #5609ee;
                        right: 0;
                        top: 4%;
                    }
                    &:nth-of-type(2) {
                        font-size: 24px;
                        color: #89c171;
                        top: 10%;
                        left: 0;
                    }
                    &:nth-of-type(3) {
                        font-size: 14px;
                        color: #6bafcb;
                        right: 20px;
                        top: 26%;
                    }
                    &:nth-of-type(4) {
                        font-size: 30px;
                        color: #4c65b7;
                        left: 10%;
                        top: 36%;
                    }
                    &:nth-of-type(5) {
                        font-size: 14px;
                        color: #cc5a5c;
                        top: 60%;
                    }
                    &:nth-of-type(6) {
                        font-size: 22px;
                        color: #f3c257;
                        left: 35%;
                        top: 64%;
                    }
                    &:nth-of-type(7) {
                        font-size: 18px;
                        color: #836caa;
                        left: 5%;
                        top: 84%;
                    }
                    &:nth-of-type(8) {
                        font-size: 14px;
                        color: #9aae69;
                        right: 5%;
                        top: 90%;
                    }
                }
            }
        }
        footer {
            .bottom-bg {
                width: 100%;

                height: 0.06rem;
                box-sizing: border-box;
                text-align: center;
                img {
                    width: 90%;
                    display: inline-block;
                    margin-top: -36/100rem;
                }
            }

            > div {
                box-sizing: border-box;
                font-size: 14/100rem;
                color: rgba(44, 160, 249, 0.8);
                width: 100%;
                //height: 20/100rem;
                text-align: center;
                &:last-of-type {
                    margin-top: 10/100rem;
                }
                span {
                    margin-right: 2%;
                }
            }
        }
        .layout {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 999999;
            background: RGBA(0, 0, 0, 0.6);

            .content {
                overflow-y: auto;
                background: #fff;
                border-radius: 20px;
                font-size: 14px;
                padding: 20px;
                width: 50vw;
                height: 80vh;
                position: relative;
                top: 50%;
                left: 50%;
                z-index: 999999;
                transform: translate(-50%, -50%);
                > div {
                    height: 70vh;
                    overflow-y: auto;
                }
                .btn {
                    width: 80%;
                    height: 50px;
                    background: #2a7af5;
                    color: #fff;
                    margin: 20px auto;
                    position: fixed;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 12px;
                }
            }
        }
    }
</style>
