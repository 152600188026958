import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '../views/home.vue';

Vue.use(VueRouter);

const SCRESS_TRUE = [
    {
        path: '/login',
        component: () => import('../views/login.vue'),
        meta: {
            keepAlive: true
        }
    }
];

const ROUTES = [
    {
        path: '/',
        component: home,
        meta: {
            navNum: 0
        }
    },
    {
        path: '*',
        component: () => import('../views/404.vue')
    },
    {
        path: '/home',
        name: '首页',
        component: home,
        meta: {
            navNum: 0
        }
    },
    {
        path: '/detail',
        name: '详情',
        component: () => import('../views/detail.vue'),
        meta: {
            navNum: 0
        }
    }
];

const routes = SCRESS_TRUE.concat(ROUTES);
const router = new VueRouter({
    routes
});

//router.beforeEach((to, from, next) => {
//    const userInfo = window.localStorage.getItem('token');

//    if (userInfo) {
//        to.meta.navNum && localStorage.setItem('navCurrent', to.meta.navNum);
//        to.meta.navNum && Vue.prototype.$store.commit('set_navCurrent', to.meta.navNum);
//        if (to.path == '/login') {
//            window.localStorage.removeItem('token');
//        }
//        next();
//    } else {
//        if (to.path == '/login') {
//            next();
//        } else {
//            next('/login');
//        }
//    }
//});

export default router;
